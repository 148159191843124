//@import url("~reset-css");
@import "./variables";
@import "./utils";
@import "./components";
@import "./reset";

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-font-color;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  * {
    box-sizing: border-box;
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1100px;
  }

  h1 {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }

  h3 {
    font-size: $h3-font-size;
    line-height: 1.7rem;
  }

  h4 {
    font-size: $h4-font-size;
  }

  a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
  }

  p {
    line-height: 1.5rem;
  }

  strong {
    font-weight: $bold-font-weight;
  }

  em {
    font-style: italic;
  }

  #root,
  #__next {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    & > div:first-of-type {
      flex: 1;
    }
  }

  //.container {
  //  width: $container-width;
  //  max-width: 100vw;
  //  margin: 0 auto;
  //  padding: 0 $spacer;
  //
  //  @media (max-width: $medium-screen) {
  //    width: 100%;
  //  }
  //}

  table {
    width: 100%;
    margin: $spacer * 3 0;
    font-size: $small-font-size;

    th {
      color: $gray-dark;
      text-align: left;
      padding: $spacer 0.5rem;
    }

    tr {
      color: $base-font-color;
      border-bottom: 1px solid $gray-light;
    }

    td {
      padding: $spacer 0.5rem;
      vertical-align: middle;

      img {
        vertical-align: middle;
        margin-right: $spacer;
      }
    }
  }

  .checkbox {
    position: relative;
    display: inline;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.4rem;
      top: 0.05rem;
      width: 0.3rem;
      height: 0.6rem;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 1;
    }

    input {
      display: none;

      &:checked ~ span {
        &:before {
          background-color: $blue;
        }
      }
    }

    span {
      margin-left: 0.5rem;

      &:before {
        background-color: $white;
        border-radius: 0.2rem;
        border: 1px solid $blue;
        content: "";
        display: block;
        height: 1rem;
        position: absolute;
        top: 0;
        width: 1rem;
      }
    }
  }
}

.form-error {
  color: $rose;
}


.hero-title {
  font-size: $h2-font-size;
  font-weight: $bold-font-weight;
  color: $base-header-color;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.link {
  color: $blue;
}






.custom-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10002;
    padding: 30px;
    width:  100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition:visibility 0s linear 0.1s,opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-modal.open {
  visibility:visible;
  opacity: 1;
  transition-delay:0s;
}

.custom-modal__overlay {
    position: fixed;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10003;
    background-color: rgba(0,0,0,0.5);
}

.custom-modal__container {
    position: relative;
    z-index: 10004;
    width: 100%;
    max-width: 600px;
    min-height: 250px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
}
.custom-modal__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
}

.custom-modal__header svg {
    cursor: pointer;
}
.custom-modal__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    text-align: center;
    line-height: 30px;
    padding: 10px 24px 30px 24px;;
}
.custom-modal__action {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 30px;
}
.custom-modal__button {
    padding: 18px 60px;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    background-color: green;
    cursor: pointer;
}


#lds-dual-ring {
    display:inline-block;
    //width:80px;
    //height:80px;
}

#lds-dual-ring:after {
    content:" ";
    display:block;
    width:64px;
    height:64px;
    margin:8px;
    border-radius:50%;
    border:6px solid #09035d;
    border-color: #09035d transparent #09035d transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
